import axiosInstance from '../../utils/axios-config';
import URL from '../endpoints';

const getResourcesBySkillId = async (skillId: string) => {
  const result = await axiosInstance.get(
    URL.content.resources.getResourcesBySkillId(skillId),
  );
  return result.data;
};

export default {
  getResourcesBySkillId,
};
