import React from 'react';
import { Spin } from 'antd';
import styles from './loader.module.css';

interface LoaderProps {}

const Loader: React.FC<LoaderProps> = () => (
  <div className={styles.centerWrapper}>
    <Spin />
  </div>
);

export default Loader;
